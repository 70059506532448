.dnaRestaurant{
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title{
    text-align: center;
  }
  &__description{
    text-align: center;
    max-width: 60%;
  }
  &__click{
    font-size: 2em;
    @media(max-width: $breakpoint-tablet){
      text-align: center;
    }
  }
  &__preview{
    &-image{
      max-width: 50vw;
      transition: all 500ms ease-in-out;
      &:hover, &active{
        background-color: white;
        cursor: pointer;
      }

      &-container{
        display: flex;
      }
    }

  }
}

.dnaBakery{
  &__image{
    max-width: 80vw;
  }
}