.homepage {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__services,
  &__section,
  &__growbusiness,
  &__process {
    display: flex;
    justify-content: center;
    margin: 5em 0;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      margin: 2em 0;
    }
    &-container {
      display: flex;
      width: 50%;
    }
    &-textContainer {
      width: 50%;
      display: flex;
      padding: 0 3em;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: $breakpoint-tablet) {
        text-align: center;
        width: 90%;
        padding: 0;
        align-self: center;
        align-items: center;

      }
    }
    &-text {
      width: 50%;
      font-size: $fs-h4;
      @media (max-width: $breakpoint-tablet) {
        width: 80%;
        font-size: $fs-mobile-h3;
      }
    }
  }
  &__section{
    &-image{
      max-width: 50%;
      height: auto;
      @media(max-width: $breakpoint-tablet){
        align-self: center;
      }
    }
  }
  &__process {
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column-reverse;
    }

    &-textContainer {
      text-align: end;
      align-items: center;
      text-align: center;

      @media (max-width: $breakpoint-tablet) {
        text-align: center;
        width: 90%;
        padding: 0;
        align-self: center;
        align-items: center;
      }
    }
  }

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__logo {
      width: 50%;
      margin-top: -10%;
      margin-bottom: -5%;
    }
    &__image {
      width: 80vw;
      height: auto;
    }
    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    &__buttons {
      margin: 2em 0;
      width: 60%;
      display: flex;
      justify-content: space-evenly;
      @media (max-width: $breakpoint-tablet) {
        width: 80%;
        justify-content: center;
        gap: 2em;
      }
    }
    &__smalltext {
      margin: 0;
      width: 40%;
      @media (max-width: $breakpoint-tablet) {
        width: 80%;
      }
    }
    &__title {
      margin-bottom: 0.75em;
    }
  }

  .cafecanvas {
    width: 100%;
  }

  .threeDElement__container {
    display: flex;
    width: 50%;
  }
  &__linkButton {
    // max-width: fit-content;
    max-width: 40%;
    @media (max-width: $breakpoint-tablet) {
      max-width: 80%;
      width: 80%;
    }
  }
}
