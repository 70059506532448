.modal {
  background-color: $colour-secondary;
  position: fixed;
  width: 90%;
  height: 90%;
  border-radius: 20px;
  z-index: 9;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &Close {
    position: sticky;
    position: -webkit-sticky;
    align-self: auto;
    top: 0;
    margin-top: 2em;
    left: 95%;
    outline: 0px;
    border: 0px;
    margin: 10px;
    cursor: pointer;
    line-height: 1.75;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $colour-text;
    border-radius: 12px;
    padding: 6px 16px;
    background-color: $colour-secondary;
    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $colour-accent;
      box-shadow: $bs-colour-accent;
    }
  }
}

.Overlay {
  display: flex;
  position: fixed;
  @include glassEffect;
  z-index: 10;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.projectModal {
  display: flex;
  flex-direction: column;
  color: $colour-text;
  padding: 16px;
  &__image {
    max-width: 49%;
    object-fit: contain;
    width: auto;
    height: auto;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      max-width: 100%;
    }
  }
  &__firstContainer {
    display: flex;
    gap: 2em;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      width: 100%;
    }
  }
  &__secondContainer {
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 2em 0.5em;
    overflow: hidden;
    align-self: flex-start;
    margin: 1em 1em;
    @media (max-width: $breakpoint-mobile) {
      display: flex;
      flex-direction: column;
      margin: 1em 0;
      width: 100%;
    }
  }
  &__exampleImages {
    max-width: 90%;
    max-height: 800px;
    transition: transform 200ms ease-in-out;
    &:hover{
      cursor: pointer;
    }
    &--focus {
      position: absolute;
      z-index: 11;
      transform: translateY(-200px);
      height: auto;
      max-width: 80vw;
      max-height: auto;
      overflow: scroll;
      object-fit: cover;
      opacity: 1;
    }
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      justify-self: center;
      min-width: 0%;
    }
  }

  &__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__link{
    top: 20%;
    color: white;
    background-origin: padding-box;
    text-decoration: none;
    border: solid white 2px;
    padding: .5em .75em;
    font-size: 48px;
    border-radius: 15px;
    text-align: center;
    // height: 20px;
    width: 50px;
    margin: .5em;
    transition: color 200ms ease-in-out;
    transition: background 200ms ease-in-out;
    &:hover{
      background-color: white;
      color: $colour-accent;
    }
  }
}

.contact__modal{
  width: 40vw;
  height: 40vh;
  @media (max-width: $breakpoint-mobile) {
    width: 80%;
    height: 80%;
  }
  &Div{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    padding: 2em;

  }
}