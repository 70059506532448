.project{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &__title{
    margin: 1em 0;
  }
  &__description{
    font-size: $fs-h4;
    @media(max-width: $breakpoint-tablet){
      font-size: $fs-mobile-h3;
    }
  }
  &__hero{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: black;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 12%, rgba(0,0,0,1) 100%);
    z-index: 2;
    &-textContainer{
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 50%;
      @media(max-width: $breakpoint-tablet){
        width: 80%;
        margin-top: 4em;
        text-align: center;
        align-items: center;
      }
    }
    &-image{
      width: 40%;
      height: auto;
      @media(max-width: $breakpoint-tablet){
        width: 80%;
        height: auto;
      }
    }
    &-button{
      width: 70px;
    }
    @media(max-width: $breakpoint-tablet){
      flex-direction: column;
    }
  }
  &__blackoutbar{
    height: 120px;
    background: black;
    z-index: 2;
    width: 100vw;
    position: absolute;
    margin-top: -120px;
    @media(max-width: $breakpoint-tablet){
      height: 20vh;
      flex-direction: column;
    }
  }
  &__section{
    display: flex;
    flex-direction: column;
    align-items: center;
    @include glassEffect;
    width: 85vw;
    margin: 8em 0;
    &-container{
      width: 95%;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2em;
      margin: 2em;
      @media(max-width: $breakpoint-tablet){
        flex-direction: column;
      }
    }
    &-subsection{
      max-width: 20%;
      background-color: $colour-secondary;
      padding: 2em;
      border-radius: 25px;
      @media(max-width: $breakpoint-tablet){
        max-width: 80%;
      }
    }
    &-description{
      width: 45%;
      text-align: center;
      font-size: $fs-h4;
      color: $colour-text;
      font-size: 26px;
      line-height: 38px;
      font-weight: 100;
      margin: 0;
      @media(max-width: $breakpoint-tablet){
        width: 80%;
        font-size: $fs-mobile-h3;
        line-height: 1.5em;
      }
    }
    &-title{
      @media(max-width: $breakpoint-tablet){
        text-align: center;
      }
    }
    &-subtitle{
      @media(max-width: $breakpoint-tablet){
        font-size: $fs-mobile-h2;
        text-align: center;
      }
    }
  }

  &__cogs{
    transform: rotate(90deg);
    height: 90px;
    width: 240px;
    margin: 2em 0;
  }

  &__nextbutton{
    margin: 1em 0 2em 0;
  }
  
}