.contact {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  &Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    margin-bottom: 2em;
    background-color: $colour-primary-10;
    padding: 4em;
    border-radius: 30px;
    box-shadow: 0 0 30px #fff, -10px 0 40px #f0f, 10px 0 40px #0ff;
    margin-bottom: 8em;
    @media(max-width: $breakpoint-tablet){
      width: 80vw;
      padding: 3em 1em;
    }
    &__input {
      &-container {
        display: flex;
        gap: 1.88rem;
        justify-content: space-between;
        align-items: center;
        width: 29.5rem;
        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;
        }
      }
      border-radius: 0.625rem;
      border: 1px solid #000;
      background: #fdfdfd;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      width: 22.0625rem;
      height: 1.8125rem;
      flex-shrink: 0;
      padding-left: 1em;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        width: 60%;
      }
      &-large {
        min-height: 8em;
      }
    }
  }
}
