.comingSoon{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__logo{
    max-width: 50vw;
    @media (max-width: $breakpoint-mobile) {
      max-width: 80vw;
    }
  }
}