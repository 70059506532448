.works {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(16, 16, 16);
  background: linear-gradient(
    0deg,
    rgba(16, 16, 16, 1) 0%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
  &__project {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 8em 0;
    padding: 2em;
    @media (max-width: $breakpoint-tablet) {
      margin: 4em 0;
      flex-direction: column;
      align-items: center;
    }
    &-text-container {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      @media (max-width: $breakpoint-tablet) {
        text-align: center;
        align-items: center;
        justify-content: center;
        max-width: 90%;
        margin-bottom: 2em;
      }
      &-inverse {
        align-items: flex-end;
        @media (max-width: $breakpoint-tablet) {
          text-align: center;
          align-items: center;
          justify-content: center;
          max-width: 90%;
        }
      }
    }
    &-button {
      width: 180px;
    }
    &-inverse {
      text-align: right;
      flex-direction: row-reverse;
      @media (max-width: $breakpoint-tablet) {
        margin: 4em 0;
        flex-direction: column;
        align-items: center;
      }
    }
    &-image {
      right: 10%;
      width: 600px;
      @media (max-width: $breakpoint-tablet) {
        max-width: 80vw;
      }
      // height: 400px;
      &-container {
        display: flex;
        align-items: center;
      }
    }
  }
}

// #altidroneimage{
//   position: absolute;
//   left: 0;
//   max-width: 1000px;
// }
