.footer{
  background-color: $colour-background-complement;
  display: flex;
  justify-content: space-evenly;
  padding: 2em 0;
  z-index: 3;
  position: absolute;
  width: 100vw;
  @media (max-width: $breakpoint-tablet) {
      display: grid;
      grid-template-columns: auto;
  }
  &__title{
    font-size: 1.333rem;
    margin: 0;
    font-weight: normal;
  }
  &__container{
    display: flex;
    flex-direction: column;
  }
  &__link{
    color: rgb(192, 192, 192);
    margin: 0.5em 0;
    text-decoration: none;
    transition: color 100ms ease-in-out;
    &:hover, &:focus{
      color: rgb(216, 216, 216);
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: $fs-mobile-h4;
    }
  }
}