html,
body {
  margin: 0;
  padding: 0;
  background-color: $colour-background;
  scroll-behavior: smooth;
  scroll-padding-top: 4em;
  overflow-x: hidden;
}

html {
  scroll-snap-type: y mandatory;

}
