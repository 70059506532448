.story{
  display: flex;
  flex-direction: column;
  &__header{
    min-height: 80vh;
    text-align: center;
    @media(max-width: $breakpoint-tablet){
      min-height: 0vh;
    }
    &-video{
      width: 100vw;
      z-index: 5;
    }
  }
  &__video{
    &2{
      width: 50vw;
      @media(max-width: $breakpoint-tablet){
        width: 100vw;
      }
      &--elevated{
        z-index: 5;
      }
    }
  }
  &__parallaxContainer{
    max-height: 40vh;
    padding: 50px 0;
    overflow: hidden;
    & video{
      position: fixed;
      top: 0;
      z-index: 0;
    }
  }
  &__title{
    position: relative;
  }
  &__subtitle{
    text-align: center;
  }
  &__section{
    position: relative;
    padding: 4em;
    margin: 0;
    width: 50vw;
    height: 100vh;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    @media(max-width: $breakpoint-tablet){
      width: 80%;
      height: 50vh;
    }
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    // backdrop-filter: blur(30px);
    // -webkit-backdrop-filter: blur(5px);

    &-1{
      display: flex;
      @media(max-width: $breakpoint-tablet){
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &--inverse{
        @media(max-width: $breakpoint-tablet){
          flex-direction: column-reverse;
        }
      }
    }
    &--centered{
      align-self: center;
    }
  }
  &__text{
    font-size: $fs-h3;
    @media(max-width: $breakpoint-tablet){
      font-size: $fs-mobile-h3;
      line-height: 2em;
      text-align: center;
    }
    @media(max-width: $breakpoint-mobile){
      line-height: 1.5em;
    }
    &--centered{
      text-align: center;
    }
  }
}