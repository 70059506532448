.mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  &__hero {
    text-align: center;
    &Video {
      width: 100vw;
      position: relative;
    }
    &-title {
      &-container {
        // position: absolute;
        // top: 15%;
        text-align: center;
        width: 100vw;
        @include glassEffect;
        @media (max-width: $breakpoint-tablet) {
          margin: 2em 0;
        }
      }
    }
  }
  &__subtitle {
    text-align: center;
    @media (max-width: $breakpoint-tablet) {
      font-size: $fs-mobile-h1;
    }
  }

  &__values {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 4em 0;
    @media (max-width: $breakpoint-tablet) {
      padding: 2em 0;
    }
    &-container {
      max-width: 30%;
      text-align: center;
      line-height: 2em;
      background: rgb(32, 32, 32);
      background: rgb(32, 32, 32);
      background: linear-gradient(
        320deg,
        rgba(32, 32, 32, 1) 56%,
        rgba(116, 116, 116, 1) 91%,
        rgba(73, 73, 73, 1) 100%
      );
      border-radius: 2em;
      padding: 2em;
      margin: 4em 0;
      box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f,
        inset -20px 0 80px #0ff, inset 20px 0 300px #f0f,
        inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f,
        10px 0 80px #0ff;
      &-center {
        align-self: center;
        justify-self: center;
      }
      &-end {
        align-self: flex-end;
      }

      @media (max-width: $breakpoint-tablet) {
        max-width: 80%;
      }

    }
    &-reveal{
      &-right{
        display: flex;
        justify-content: flex-end;
      }
      &-center{
        display: flex;
        justify-content: center;
      }
    }
    &-subtitle{
      @media (max-width: $breakpoint-tablet) {
        font-size: $fs-mobile-h2;
      }
    }
  }

  &__fulfillment {
    background-color: $colour-primary-10;
    margin: 4em 0;
    padding: 4em 0;
    &-content {
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 2em;
    }
    &-container {
      width:100vw;
      &:first-child{
        margin-top: 0em;
      }
      margin: 6em 0 2em 0;
      max-width: 50%;
      &-inverse {
        text-align: right;
      }
      @media (max-width: $breakpoint-tablet) {
        text-align: center;
        max-width: 80%;
      }
    }
    &-video {
      border-radius: 30%;
      max-width: 50vw;
      @media (max-width: $breakpoint-tablet) {
        max-width: 90vw;
      }
      // box-shadow:
      //   0 0 5px 5px #fff,  
      //   0 0 20px 20px rgb(255, 19, 255), 
      //   0 0 25px 25px #0ff;
    }
    &-subtitle{
      @media (max-width: $breakpoint-tablet) {
        font-size: $fs-mobile-h2;
      }
    }
  }
  &__join {
    margin: 0 auto 4em auto;
    padding: 4em 0;
    width: 80%;
    &-text {
      line-height: 2em;
      text-align: center;
      @media (max-width: $breakpoint-tablet) {
        line-height: 1.5em;
      }
    }
    &-button {
      &-container {
        display: flex;
        justify-content: center;
        gap: 4em;

          @media (max-width: $breakpoint-tablet) {
            gap: 2em;
          }
        
      }

        @media (max-width: $breakpoint-tablet) {
          font-size: $fs-mobile-h2;
        }
      
    }
  }
}
