.navbar{
  width: 100vw;
  margin: 0 5em;
  padding: 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  background: black;
  z-index: 5;
  position: relative;

  &__menu{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    background: black;
    z-index: 5;
    position: relative;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin:0;
      width: 100%;
    }
    &LI{
      list-style: none;
      text-align: left;
      padding: 0.5em 0;
    }
  }
  &__navigation{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 30%;
    max-height: 30px;
    & nav{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: $breakpoint-tablet) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
      align-items: center;
      justify-content: center;
      max-width: 90vw;
      width: 90%;
    }
  }
  &__link{
    color: white;
    text-decoration: none;
    cursor: pointer;
    margin: 0 2em;
    &--active{
      color: white;
      text-decoration: none;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: $fs-mobile-h4;
    }
  }
  &__logo{
    max-height: 80px;
    min-height: 80px;
    @media (max-width: $breakpoint-mobile) {
      max-width: 10vw;
      max-height: 10vh;
    }
  }
  @media (max-width: $breakpoint-tablet) {
    max-width: 100vw;
    justify-content: center;
  }

  &__menuItem{
    color: white;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    min-width: 85px;
    text-align: center;
    width: fit-content;
    padding: 1em 2em;
    &:hover ul, &focus ul{
      display: flex;
    }
    @media(max-width: $breakpoint-mobile){
      font-size: $fs-mobile-h4;
      padding: 1em 0;
      justify-self: center;
      align-self: center;
    }
    
  }
  &__menuDropdown{
    position: absolute;
    cursor: pointer;
    z-index: 5;
    margin-top: 1em;
    background-color: rgb(46, 46, 46);
    flex-direction: column;
    padding: 1em;
    border-radius: 15px;
    display: none;
    transition: all ease-in-out 500ms;
    &--forceActive{
      display: flex;
    }
    &:hover, &:focus{
      display: flex;
    }
    &-link{
      text-align: left;
      color: white;
      text-decoration: none;
      padding: 0.5em 0;
      transition: all ease-in-out 250ms;
      &:hover, &:focus{
        color: $colour-accent;
      }
    }
  }
}

.languageSelector{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4375em;
  color: $colour-text;
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100px;
  position: relative;
  background-color: $colour-background;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-color: rgb(229, 231, 235);
  margin: 0 2em;
  &:hover{
    cursor: pointer;
  }
  @media (max-width: $breakpoint-mobile) {
    font-size: 1rem;
    width: 100px;
    margin: 0;
  }
}