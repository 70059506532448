.dropdownmenu{
  padding: 0 1em 1em 1em;
  min-height: 30px;
  align-self: flex-start;
  z-index: 9;
  max-width: 160px;
  @media (max-width: $breakpoint-tablet) {
    max-width: 20vw;
  }
}

.dropdown{
  background-color: $colour-background-complement;
  padding: 1em 2em 2em 2em;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1em;
  z-index: 10;
  &__link{
    color: white;
    text-decoration: none;
    list-style: none;
    margin: 2em 0;
    cursor: pointer;
    transition: color 500ms ease-in-out;
    &:hover, &:active{
      color: $colour-accent;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: $fs-mobile-h4;
    }
  }
  &__button{
    background: none;
    color: $colour-text;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
    margin-top: 1em;
    @media (max-width: $breakpoint-tablet) {
      font-size: $fs-mobile-h4;
      width: 20vw;
    }
  }
  &__arrow{
    color: $colour-text;
  }
}

