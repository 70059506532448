.CTAButton{
  background: $colour-primary;
  border-radius: 15px;
  border: solid lightgrey 1px;
  padding: 0.25em 1em;
  text-decoration: none;
  color: $colour-text;
  min-width: 150px;
  min-height: 2em;
  transition: all 250ms ease-in-out;
  font-size: $fs-h5;
  text-align: center;
  font-family: $ff-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $breakpoint-mobile) {
    font-size: $fs-mobile-h4;
    min-width: 0;
  }
  &:hover, &:active{
    cursor: pointer;
    background: $colour-accent;
    color: white;
    box-shadow: $bs-colour-accent;
  }
  &-dark{
    background: $colour-background-complement;
  }
  &--disabled{
    background-color: rgb(46, 46, 46);
    &:hover, &:active{
      cursor: not-allowed;
      background-color: rgb(46, 46, 46);
      box-shadow: none;
    }
  }
}