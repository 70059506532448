body {
  font-family: 'Open Sans';
  font-weight: 400;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Open Sans';
  font-weight: 700;
  color: $colour-text;
}

html {
  font-size: 100%;
} /* 16px */


h1 {
  font-size: $fs-h1; /* 67.36px */
  margin: 1em;
  @media (max-width: $breakpoint-mobile) {
    font-size: $fs-mobile-h1;
  }
}

h2 {
  font-size: $fs-h2;
  @media (max-width: $breakpoint-mobile) {
    font-size: $fs-mobile-h2;
  } /* 50.56px */
}

h3 {
  font-size: $fs-h3;
  @media (max-width: $breakpoint-mobile) {
    font-size: $fs-mobile-h3;
  } /* 37.92px */
}

h4 {
  font-size: $fs-h4;
  @media (max-width: $breakpoint-mobile) {
    font-size: $fs-mobile-h4;
  } /* 28.48px */
}

h5 {
  font-size: $fs-h5;
  @media (max-width: $breakpoint-mobile) {
    font-size: $fs-mobile-h5;
  } /* 21.28px */
}

p{
  font-size: $fs-h5;
  color: $colour-text;
}

small {
  font-size: 0.75rem;
  color: $colour-text; /* 12px */
}