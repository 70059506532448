.blob{
    background-color: white;
    height: 250px;
    aspect-ratio: 1;
    position: fixed;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    border-radius: 50%;
    animation: rotate 20s infinite;
    filter: blur(150px);
    z-index: 2;
    @media(max-width: $breakpoint-tablet){
        
      }
    
    background: linear-gradient(
        to right, 
        $colour-primary, 
        white
    )

}


@keyframes rotate{
    from {
        rotate: 0deg;
    }
    50%{
        scale: 1 1.5;
    }
    to {
        rotate: 360deg;
    }
}

